import React from "react";
import resumeFile from "../documents/TrevorKBuchanan_Resume.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2016 - 2019",
      title: "BS - Aviation Management",
      place: "Louisiana Tech University",
    },
    {
      yearRange: "2021 - Current",
      title: "Relevant Courses - Software Developer",
      place: "Bossier Parish Community College",
    },
    {
      yearRange: "2022",
      title: "Professional Scrum Master, (PSM 1)",
      place: "Scrum.org",
    },
    {
      yearRange: "2022",
      title: "Tableau Desktop Specialist",
      place: "Tableau",
    },
    {
      yearRange: "2024",
      title: "Microsoft Certified: Power Platform Fundamentals",
      place: "Microsoft",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "February 2025 - Current",
      title: "Data Analyst Senior",
      place: "General Dynamics Information Technology",
    },
    {
      yearRange: "November 2023 - February 2025",
      title: "Data Analyst",
      place: "General Dynamics Information Technology",
    },
    {
      yearRange: "January 2023 - November 2023",
      title: "Database Analyst Associate",
      place: "General Dynamics Information Technology",
    },
    {
      yearRange: "November 2019 - December 2022",
      title:
        "Lead Operational Control Specialist/Continuous Quality Improvement Analyst",
      place: "Metro Aviation, Inc.",
    },
    {
      yearRange: "March 2017 - November 2019",
      title: "Head Lineman",
      place: "Ruston Aviation, Inc.",
    },
  ];

  const skills = [
    {
      name: "Tableau & Tableau Server",
    },
    {
      name: "Power BI",
    },
    {
      name: "Microsoft Power Platform",
    },
    {
      name: "Excel & Power Query",
    },
    {
      name: "Python",
    },
    {
      name: "SQL",
    }
  ];

  return (
    <section id="resume" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24 text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <div className="row gx-5">
          {/* My Education (Narrower Column) */}
          <div className="col-lg-4">
            <h2 className={"text-6 fw-600 mb-4 text-center " + (darkTheme ? "text-white" : "")}>
              My Education & Certificates
            </h2>
            {educationDetails.map((value, index) => (
              <div
                key={index}
                className={
                  "bg-white rounded p-4 mb-4 " +
                  (darkTheme ? "bg-dark" : "bg-white border")
                }
              >
                <p className="badge bg-primary text-2 fw-400">{value.yearRange}</p>
                <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                  {value.title}
                </h3>
                <p className={darkTheme ? "text-primary" : "text-danger"}>
                  {value.place}
                </p>
              </div>
            ))}
          </div>

          {/* My Experience (Wider Column) */}
          <div className="col-lg-8">
            <h2
              className={
                "text-6 fw-600 mb-4 text-center " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            <div className="row row-cols-1 g-4">
              {experienceDetails.map((value, index) => (
                <div key={index} className="col">
                  <div
                    className={
                      "bg-white rounded p-4 " +
                      (darkTheme ? "bg-dark" : "bg-white border")
                    }
                  >
                    <p className="badge bg-primary text-2 fw-400">{value.yearRange}</p>
                    <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                      {value.title}
                    </h3>
                    <p className={darkTheme ? "text-primary" : "text-danger"}>
                      {value.place}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* My Skills */}
        <h2 className={"text-6 fw-600 mt-4 mb-4 text-center " + (darkTheme ? "text-white" : "")}>
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.map((skill, index) => (
            <div key={index} className="col-4 col-md-2 skill-container">
              {/* Replace the src path with your actual icon image path */}
              <img
                src={`../images/skills/${skill.name.toLowerCase().replace(/\s/g, "")}.png`}
                alt={skill.name}
                className="img-fluid skill-img"
              />
              <p className={`skill-text ${darkTheme ? "text-white" : ""}`}>{skill.name}</p>
            </div>
          ))}
        </div>

        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download Resume
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
